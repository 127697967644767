"use strict"
///include /assets/js/app/p/s-basket-service.js
///include /assets/js/plugins/network-property-set.js

/**
 * This is one row in a basket
 */
class SBasketItem extends NetworkPropertySet {
    /**
     * @param {POST.Stack.Basket.$ns.basket.ref.$add.input["params"][0] | GET.Stack.Basket.$ns.basket.ref.output[0]} item
     * @param {?string} [ref]
     */
    constructor(item, ref = null, dependant = false) {
        super()
        this.ref = ref
        this.action = item.action
        this.contact = item.contact
        this.currency = item.currency
        this.isDependant = dependant
        //@ts-ignore
        this.dependentItems = this.importDependentItems(item.dependentItems)
        this.label = item.label
        this.name = item.name
        this.otherValidationErrors = item.otherValidationErrors
        this.paymentRef = item.paymentRef
        this.paymentSelections = item.paymentSelections
        /** @type {generalPaymentType} */
        ///@ts-ignore
        this.paymentType = item.paymentType
        this.periodMonths = item.periodMonths
        this.productCode = item.productCode
        this.productOptionSelections = item.productOptionSelections
        this.quotedPriceFull = item.quotedPriceFull
        this.quotedVAT = item.quotedVAT
        this.service = item.service
        this.serviceConfiguration = item.serviceConfiguration
        this.upgradeFromProductCode = item.upgradeFromProductCode
        this.userValidationErrors = item.userValidationErrors
        this.expectedCharges = item.expectedCharges
    }

    /**
     * The general payment type for this and any descendant items. 1+ items.
     */
    get allGeneralPaymentTypes() {
        const out = [this.paymentType]
        for(const i of Object.values(this.dependentItems)) {
            out.push(
                ...i.allGeneralPaymentTypes
            )
        }
        return [...new Set(out)]
    }

    get identity() {
        return this.name;
    }

    get labels(){
        try {
            var labels = JSON.parse(this.label);
            return labels;
        } catch (e) {
            return null;
        }
    }

    /**
     * @type {?SBasketItem[]}
     */
    get itemsFlat() {
        if(!this.dependentItems) return null
        const out = []
        for(const item of Object.values(this.dependentItems)) {
            out.push(item, ...item.itemsFlat)
        }
        return out
    }
    get networkMethodInfo() {
        return {
            path: `/n/basket/own/${this.ref}`,
        }
    }
    get networkPropertyHandlers() {
        return {
            dependentItems: () => $.ajax(`${this.path}/dependentItems`).then(
                items => $.Deferred().resolve(this.importDependentItems(items))
            ),
        }
    }
    get path() {
        return `/n/basket/own/${this.ref}`
    }
    get price() {
        return Math.round((this.quotedPriceFull - this.quotedVAT) * 100) / 100
    }
    get serviceType() {
        return this.productCode.replace(/:.*/, "")
    }
    get supportedRealPaymentTypes() {
        return SBasketService.realTypesFor(this.paymentType)
    }
    /**
     * @returns {number}
     */
    get total() {
        if(!this.dependentItems) return this.quotedPriceFull
        return Object.values(this.dependentItems).reduce(
            (carry, item) => Math.round((carry + item.total) * 100) / 100,
            this.quotedPriceFull
        )
    }

    async current() {
        return await $.ajax(this.path)
    }

    /**
     * Export to JSON object, suitable for using to create a new instance from the data.
     * using 'new SBasketItem(exportData,export.ref,export.dependant)'
     */
    exportObject() {
        return {
            ref: this.ref,
            action: this.action,
            contact: this.contact,
            currency: this.currency,
            dependant: this.isDependant,
            dependentItems: this.dependentItems,
            label: this.label,
            name: this.name,
            otherValidationErrors: this.otherValidationErrors,
            paymentRef: this.paymentRef,
            paymentSelections: this.paymentSelections,
            paymentType: this.paymentType,
            periodMonths: this.periodMonths,
            productCode: this.productCode,
            productOptionSelections: this.productOptionSelections,
            quotedPriceFull: this.quotedPriceFull,
            quotedVAT: this.quotedVAT,
            service: this.service,
            serviceConfiguration: this.serviceConfiguration,
            upgradeFromProductCode: this.upgradeFromProductCode,
            userValidationError: this.userValidationErrors,
            expectedCharges: this.expectedCharges
        };
    }

    /**
     *
     * @param {GET.Stack.Basket.$ns.basket.ref.id.dependentItems.output} items
     */
    importDependentItems(items) {
        /** @type {{[ref: string]: SBasketItem}} */
        const dependent_items = {}
        for(let [ref, subitem] of Object.entries(items)) {
            dependent_items[ref] = new SBasketItem(
                subitem,
                this.ref && `${this.ref}/dependentItems/${ref}`,
                true
            )
        }
        return dependent_items
    }
    /**
     * @param {string} name
     */
    matchesIdentity(name) {
        return this.name && this.name.toLowerCase() == name.toLowerCase()
    }
    /**
     * @param {string} type
     * @returns {boolean}
     */
    supportsPaymentType(type) {
        return(
            //@ts-ignore
            this.supportedRealPaymentTypes.includes(type) ||
            this.dependentItems && Object.values(this.dependentItems).some(
                item => item.supportsPaymentType(type)
            )
        )
    }

    /**
     * @returns {POST.Stack.Basket.$ns.basket.ref.$add.input["params"][0]}
     */
    toJSON() {
        return {
            action: this.action,
            contact: this.contact,
            currency: this.currency,
            dependentItems: this.dependentItems,
            name: this.name,
            paymentRef: this.paymentRef,
            paymentSelections: this.paymentSelections,
            paymentType: this.paymentType,
            periodMonths: this.periodMonths,
            productCode: this.productCode,
            productOptionSelections: this.productOptionSelections,
            quotedPriceFull: this.quotedPriceFull,
            quotedVAT: this.quotedVAT,
            service: this.service,
            serviceConfiguration: this.serviceConfiguration,
            upgradeFromProductCode: this.upgradeFromProductCode,
        }
    }
}

SBasketItem.prototype.modify = NPSUtil.j(
    SBasketItem,
    "modify",
    {
        success() {
        }
    }
);

SBasketItem.prototype.add = NPSUtil.j(
    SBasketItem,
    "add",
    {
        success() {
            return $.ajax(`${this.path}/dependentItems`).done(
                items => {
                    /** @type {{[ref: string]: SBasketItem}} */
                    const dependent_items = {}
                    for(let [ref, subitem] of Object.entries(items)) {
                        dependent_items[ref] = new SBasketItem(
                            subitem,
                            this.ref && `${this.ref}/dependentItems/${ref}`
                        )
                    }
                    this.dependentItems = dependent_items
                }
            )
        },
    }
)
/**
 * @type {JSONRPC.Stack.Basket.$ns.basket.ref.$addWithInfo}
 */
SBasketItem.prototype.addWithInfo = NPSUtil.j(
    SBasketItem,
    "addWithInfo",
    {
        success() {
            return $.ajax(`${this.path}/dependentItems`).done(
                items => {
                    /** @type {{[ref: string]: SBasketItem}} */
                    const dependent_items = {}
                    for(let [ref, subitem] of Object.entries(items)) {
                        dependent_items[ref] = new SBasketItem(
                            subitem,
                            this.ref && `${this.ref}/dependentItems/${ref}`
                        )
                    }
                    this.dependentItems = dependent_items
                }
            )
        },
    }
)

if(typeof AnyBasket != "undefined") {
    //@ts-ignore
    AnyBasket.register(SBasket)
}
